import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer, Event } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../css/AvailabilityScheduler.css";
import { Menu, Item, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { Modal, Button, Form } from "react-bootstrap"; // Import Bootstrap modal and form
import axios from "../axiosConfig"; // Ensure axios is correctly configured

const localizer = momentLocalizer(moment);

interface CustomEvent extends Event {
  style?: React.CSSProperties;
}

const AvailabilityScheduler: React.FC = () => {
  const [events, setEvents] = useState<CustomEvent[]>([]);
  const { show } = useContextMenu({
    id: "availability-menu",
  });

  const [editEvent, setEditEvent] = useState<CustomEvent | null>(null);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete modal
  const [editedStart, setEditedStart] = useState<Date | null>(null); // State for edited start time
  const [editedEnd, setEditedEnd] = useState<Date | null>(null); // State for edited end time
  const [eventToDelete, setEventToDelete] = useState<CustomEvent | null>(null); // State to track the event to delete

  useEffect(() => {
    const fetchAvailabilityAndSparrings = async () => {
      try {
        const availabilityResponse = await axios.get("/profile/availability/");
        let availabilities = availabilityResponse.data.map((event: any) => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end),
          style: { backgroundColor: "#8d493a", borderColor: "#8d493a" },
          title: "Available",
        }));

        // Fetch sparring requests
        const sparringResponse = await axios.get("/my-sparrings/");
        const currentUserID = sparringResponse.data.current_user_id;

        const sparrings = sparringResponse.data.accepted_requests
          .map((sparring: any) => {
            if (
              sparring.selected_slot &&
              sparring.selected_slot.start &&
              sparring.selected_slot.end
            ) {
              const requester = sparring.requester || {};
              const offerUser = sparring.offer || {};

              const isRequester = requester.id === currentUserID;
              const partnerUsername = isRequester
                ? offerUser.user.username
                : requester.username;

              if (!partnerUsername) {
                console.warn("Sparring partner not found:", sparring);
                return null;
              }

              return {
                ...sparring,
                start: new Date(sparring.selected_slot.start),
                end: new Date(sparring.selected_slot.end),
                title: `Sparring with ${partnerUsername}`,
                style: { backgroundColor: "#6b4f4f", borderColor: "#6b4f4f" },
              };
            } else {
              console.warn(
                "selected_slot est null ou ne contient pas les propriétés nécessaires:",
                sparring
              );
              return null;
            }
          })
          .filter(
            (event: CustomEvent | null): event is CustomEvent => event !== null
          );

        // Ajuster les créneaux de disponibilité pour éviter les conflits avec les sparrings
        sparrings.forEach((sparring: CustomEvent) => {
          availabilities = availabilities.flatMap(
            (availability: CustomEvent) => {
              if (
                (sparring.start >= availability.start &&
                  sparring.start < availability.end) ||
                (sparring.end > availability.start &&
                  sparring.end <= availability.end)
              ) {
                const adjustedSlots: CustomEvent[] = [];

                // Créer un créneau avant le sparring
                if (sparring.start > availability.start) {
                  adjustedSlots.push({
                    ...availability,
                    end: sparring.start,
                  });
                }

                // Créer un créneau après le sparring
                if (sparring.end < availability.end) {
                  adjustedSlots.push({
                    ...availability,
                    start: sparring.end,
                  });
                }

                return adjustedSlots;
              }
              // Si pas de chevauchement, conserver la disponibilité telle quelle
              return [availability];
            }
          );
        });

        // Combine adjusted availability and sparring events
        setEvents([...availabilities, ...sparrings]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAvailabilityAndSparrings();
  }, []);

  const dayPropGetter = (date: Date) => {
    if (moment(date).isBefore(moment(), "day")) {
      return {
        className: "past-day",
      };
    }
    return {};
  };

  const handleSelectSlot = ({ start, end }: { start: Date; end: Date }) => {
    const newEvent = {
      start,
      end,
      title: "Available",
      style: { backgroundColor: "#8d493a", borderColor: "#8d493a" },
    };

    setEvents([...events, newEvent]);

    // Save new event to the server
    saveAvailability([...events, newEvent]);
  };

  const handleEventEdit = (event: CustomEvent) => {
    setEditEvent(event);
    setEditedStart(event.start);
    setEditedEnd(event.end);
    setShowModal(true); // Show the modal for editing
  };

  const handleSaveEdit = () => {
    if (editEvent && editedStart && editedEnd) {
      const updatedEvents = events.map((e) =>
        e === editEvent ? { ...e, start: editedStart, end: editedEnd } : e
      );
      setEvents(updatedEvents);
      setShowModal(false); // Hide the modal after saving

      // Save the updated events to the server
      saveAvailability(updatedEvents);
    }
  };

  const handleEventDelete = (event: CustomEvent) => {
    setEventToDelete(event); // Track the event to delete
    setShowDeleteModal(true); // Show the delete confirmation modal
  };

  const confirmDeleteEvent = () => {
    if (eventToDelete) {
      const updatedEvents = events.filter((e) => e !== eventToDelete);
      setEvents(updatedEvents);
      setShowDeleteModal(false); // Hide the modal after deleting

      // Save the updated events to the server
      saveAvailability(updatedEvents);
    }
  };

  const handleContextMenu = (
    event: React.MouseEvent,
    calendarEvent: CustomEvent
  ) => {
    event.preventDefault();
    show({
      event,
      props: {
        event: calendarEvent,
      },
    });
  };

  const handleEventResize = ({
    event,
    start,
    end,
  }: {
    event: CustomEvent;
    start: Date;
    end: Date;
  }) => {
    const updatedEvents = events.map((e) =>
      e === event ? { ...e, start: new Date(start), end: new Date(end) } : e
    );
    setEvents(updatedEvents);

    // Save the updated events to the server
    saveAvailability(updatedEvents);
  };

  const handleEventDrop = ({
    event,
    start,
    end,
  }: {
    event: CustomEvent;
    start: Date;
    end: Date;
  }) => {
    const updatedEvents = events.map((e) =>
      e === event ? { ...e, start: new Date(start), end: new Date(end) } : e
    );
    setEvents(updatedEvents);

    // Save the updated events to the server
    saveAvailability(updatedEvents);
  };

  // Save availability to the API
  const saveAvailability = async (availabilities: CustomEvent[]) => {
    try {
      await axios.post("/profile/availability/", availabilities);
    } catch (error) {
      console.error("Error saving availability:", error);
    }
  };

  return (
    <div style={{ height: "700px" }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        selectable
        resizable
        onSelectSlot={handleSelectSlot}
        defaultView="week"
        views={["week", "day"]}
        step={30}
        timeslots={2}
        style={{ height: "100%" }}
        onEventResize={handleEventResize}
        onEventDrop={handleEventDrop}
        dayPropGetter={dayPropGetter}
        components={{
          eventWrapper: ({
            event,
            children,
          }: {
            event: CustomEvent;
            children: React.ReactNode;
          }) => (
            <div
              onContextMenu={(e) => handleContextMenu(e, event)}
              style={{ height: "100%" }}
            >
              {children}
            </div>
          ),
        }}
        eventPropGetter={(event: CustomEvent) => {
          // Check if the event is a sparring
          const isSparring = event.title && event.title.startsWith("Sparring");

          return {
            className: isSparring ? "sparring-event" : "availability-event", // Apply different className
            style: {
              backgroundColor: event.style?.backgroundColor,
              borderColor: event.style?.borderColor || "red",
              fontSize: isSparring ? "12px" : "14px", // Adjust font size for sparrings
            },
          };
        }}
      />

      <Menu id="availability-menu">
        <Item onClick={({ props }) => props && handleEventEdit(props.event)}>
          Edit
        </Item>
        <Item onClick={({ props }) => props && handleEventDelete(props.event)}>
          Remove
        </Item>
      </Menu>

      {/* Modal for editing availability */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Availability</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formStartTime">
              <Form.Label>Start Time</Form.Label>
              <Form.Control
                type="datetime-local"
                value={moment(editedStart).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) =>
                  setEditedStart(moment(e.target.value).toDate())
                }
              />
            </Form.Group>
            <Form.Group controlId="formEndTime">
              <Form.Label>End Time</Form.Label>
              <Form.Control
                type="datetime-local"
                value={moment(editedEnd).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) => setEditedEnd(moment(e.target.value).toDate())}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveEdit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for confirming delete */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this availability?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteEvent}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AvailabilityScheduler;
