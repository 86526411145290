import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import AboutYou from "./pages/AboutYou";
import ListSparringOffers from "./pages/ListSparringOffers";
import MySparrings from "./pages/MySparrings";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./css/custom.css";
import { AuthProvider, useAuth } from "./AuthContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import axiosConfig from "./axiosConfig";
import ProtectedRoute from "./components/ProtectedRoute";
import RegistrationSuccess from "./pages/RegistrationSuccess";
import ConfirmEmail from "./pages/ConfirmEmail";
import WeeklySummaries from "./components/WeeklySummaries";
import PasswordResetConfirm from "./components/PasswordResetConfirm";
import PasswordResetRequest from "./components/PasswordResetRequest";
import PaymentPage from "./pages/PaymentPage";
import PaymentResultPage from "./pages/PaymentResultPage";
import AllSparringsPage from "./pages/AllSparringsPage";
import GoogleCallback from "./pages/GoogleCallback";
import RegisterGoogleSuccess from "./pages/RegisterGoogleSuccess";

import Cookies from "js-cookie";
import { Toaster } from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Charger la clé Stripe
const stripePromise = loadStripe(
  "pk_test_51ObMdqEmhWe8zXr026Kx9PU7GGaZqHZldpCMBXPrUDvLHx3Dxe7BJHs63cu8SBFXxXQ8Hv1QLp5YK2J6mqOFNjk6000eI0yTie"
);

const LogoutRedirect: React.FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    Cookies.remove("csrftoken");
    Cookies.remove("sessionid");
    navigate("/login");
  }, [logout, navigate]);

  return null;
};

const AppContent: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const isAuthPage =
    location.pathname === "/login" || location.pathname === "/register";
  const isAboutYouPage = location.pathname === "/about-you";
  const hideHeaderFooter = location.pathname === "/premium";

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        await axiosConfig.get("/set-csrf-cookie/");
      } catch (error) {
        console.error("Error setting CSRF token", error);
      }
    };

    fetchCsrfToken();
  }, []);

  useEffect(() => {
    if (user && location.pathname === "/") {
      navigate("/my-sparrings");
    }
  }, [user, location.pathname, navigate]);

  return (
    <div className="app-content">
      {!isAuthPage && !hideHeaderFooter && !isAboutYouPage && <Header />}
      <div
        className={`container ${
          isAuthPage || isAboutYouPage ? "auth-page" : ""
        } ${hideHeaderFooter ? "no-header" : "mt-5"}`}
      >
        <Toaster position="top-right" reverseOrder={false} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<LogoutRedirect />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/profile"
            element={<ProtectedRoute element={<Profile />} />}
          />
          <Route
            path="/dashboard"
            element={<ProtectedRoute element={<ListSparringOffers />} />}
          />
          <Route
            path="/my-sparrings"
            element={<ProtectedRoute element={<MySparrings />} />}
          />
          <Route path="/about-you" element={<AboutYou />} />
          <Route
            path="/registration-success"
            element={<RegistrationSuccess />}
          />
          <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
          <Route path="/weekly-summaries" element={<WeeklySummaries />} />
          <Route path="/reset-password" element={<PasswordResetRequest />} />
          <Route
            path="/reset-password-confirm/:uidb64/:token"
            element={<PasswordResetConfirm />}
          />
          <Route path="/payment" element={<PaymentResultPage />} />
          <Route
            path="/premium"
            element={
              <Elements stripe={stripePromise}>
                <PaymentPage />
              </Elements>
            }
          />
          <Route path="/all-sparrings" element={<AllSparringsPage />} />
          <Route path="/auth/callback" element={<GoogleCallback />} />
          <Route
            path="/registergoogle=success"
            element={<RegisterGoogleSuccess />}
          />
        </Routes>
      </div>
      {!isAuthPage && !hideHeaderFooter && !isAboutYouPage && <Footer />}
    </div>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
};

export default App;
