import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../axiosConfig";

const ConfirmEmail: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      axios
        .get(`/confirm-email/${token}/`)
        .then((_response) => {
          console.log("Email confirmed");
          navigate("/about-you"); // Redirect to "About You" page after confirmation
        })
        .catch((error) => {
          console.error("Email confirmation failed", error);
        });
    }
  }, [token, navigate]);

  return (
    <div className="confirm-email">
      <h2>Confirming your email...</h2>
    </div>
  );
};

export default ConfirmEmail;
