import React from "react";

interface CountryFlagProps {
  countryCode: string;
}

const CountryFlag: React.FC<CountryFlagProps> = ({ countryCode }) => {
  if (countryCode === "unknown") {
    return null; // Or render a default flag or message
  }
  const flagUrl = `https://flagcdn.com/${countryCode.toLowerCase()}.svg`;
  return (
    <img
      src={flagUrl}
      alt={`${countryCode} flag`}
      style={{ width: "30px", height: "20px" }}
    />
  );
};

export default CountryFlag;
