import React, { useState, FormEvent } from "react";
import { motion } from "framer-motion";
import { Modal, Accordion, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faQuoteLeft,
  faQuoteRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../axiosConfig";
import { useInView } from "react-intersection-observer";
import "../css/Home.css";

const Home: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [dotPosition, setDotPosition] = useState({ left: 0 });
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [wantsMeeting, setWantsMeeting] = useState<boolean>(false);

  // URLs directes des images
  const thumbnailUrl = "https://peerchess.com/media/images/miniature.png";
  const heroImageUrl = "https://peerchess.com/media/images/hero.png";

  const handleMouseMove = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const boundingRect = event.currentTarget.getBoundingClientRect();
    const mouseX = event.clientX - boundingRect.left;
    setDotPosition({ left: mouseX });
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleRegisterClick = () => {
    navigate("/register");
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      wants_meeting: wantsMeeting,
    };

    try {
      const response = await axiosConfig.post(
        "/api/send-premium-interest-email/",
        data
      );
      if (response.status === 200) {
        alert("Thank you! We will reach out to you soon.");
        // Réinitialiser le formulaire après envoi réussi
        setFirstName("");
        setLastName("");
        setEmail("");
        setWantsMeeting(false);
      } else {
        alert("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error("There was an error sending the email", error);
      alert("There was an error sending your request. Please try again.");
    }
  };

  // Intersection Observer Hooks
  const { ref: firstSectionRef, inView: firstSectionInView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const { ref: statsSectionRef, inView: statsSectionInView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const { ref: videoSectionRef, inView: videoSectionInView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const { ref: pricingSectionRef, inView: pricingSectionInView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const { ref: testimonialsSectionRef, inView: testimonialsSectionInView } =
    useInView({
      triggerOnce: true,
      threshold: 0.3,
    });

  const { ref: bookingSectionRef, inView: bookingSectionInView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const { ref: faqSectionRef, inView: faqSectionInView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <div className="home">
      <motion.div
        className="section"
        initial={{ opacity: 0, x: -100 }}
        animate={{
          opacity: firstSectionInView ? 1 : 0,
          x: firstSectionInView ? 0 : -100,
        }}
        transition={{ duration: 1 }}
        ref={firstSectionRef}
      >
        <div className="text-section">
          <h1>
            PeerChess,
            <br />
            <span className="highlighted-text">Your Weekly Chess Boost</span>
          </h1>
          <p>
            PeerChess offers personalized chess training with weekly sparring
            matches and tailored feedback from top coaches. Elevate your game
            with customized strategies and focused improvement.
          </p>
          <button
            className="btn btn-primary btn-lg"
            onClick={handleRegisterClick}
          >
            Register now
          </button>
        </div>

        <div className="list-section">
          <div className="philosophy-title">
            <h2>Our Philosophy to Chess Improvement in 4 steps</h2>
          </div>
          <div className="list-item">
            <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
            <p>Watch the pre-sparring video</p>
          </div>
          <div className="list-item">
            <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
            <p>
              Play & practice the position of the week.
              <br /> 6 games, Time control: 4+2
            </p>
          </div>
          <div className="list-item">
            <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
            <p>Watch the post-sparring video</p>
          </div>
          <div className="list-item premium-item">
            <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
            <p>Feedback of your games from a GM coach!</p>
            <span className="premium-badge">Premium</span>
          </div>
        </div>
      </motion.div>

      <motion.div
        className="home-stats-wrapper"
        onMouseMove={handleMouseMove}
        initial={{ opacity: 0, y: 100 }}
        animate={{
          opacity: statsSectionInView ? 1 : 0,
          y: statsSectionInView ? 0 : 100,
        }}
        transition={{ duration: 1 }}
        ref={statsSectionRef}
      >
        <div className="dot" style={dotPosition}></div>
        <div className="home-stat-module">
          <h2>Scheme repetition and pattern recognition</h2>
        </div>
        <div className="home-stat-module">
          <h2>Personalized feedback</h2>
        </div>
        <div className="home-stat-module">
          <h2>General knowledge</h2>
        </div>
        <div className="home-stat-module">
          <h2>Community spirit</h2>
        </div>
      </motion.div>

      <motion.div
        className="section video-section"
        initial={{ opacity: 0, x: 100 }}
        animate={{
          opacity: videoSectionInView ? 1 : 0,
          x: videoSectionInView ? 0 : 100,
        }}
        transition={{ duration: 1 }}
        style={{ display: "flex" }}
        ref={videoSectionRef}
      >
        <div className="thumbnail-container" style={{ flex: 1 }}>
          <img src={thumbnailUrl} alt="Thumbnail" onClick={handleShowModal} />
        </div>
        <div className="image-section">
          <img src={heroImageUrl} alt="Hero" className="hero-image" />
        </div>
      </motion.div>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        size="lg"
        className="custom-modal"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div className="modal-video">
            <iframe
              src="https://www.youtube.com/embed/dQw4w9WgXcQ"
              frameBorder="0"
              allow="autoplay; fullscreen"
              title="Introduction Video"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>

      <motion.div
        className="pricing-section"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{
          opacity: pricingSectionInView ? 1 : 0,
          scale: pricingSectionInView ? 1 : 0.9,
        }}
        transition={{ duration: 1 }}
        ref={pricingSectionRef}
      >
        <div className="pricing-card">
          <h3>Free Plan</h3>
          <p>Perfect for casual players</p>
          <ul>
            <li>
              <div className="icon-text-container">
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />{" "}
                Unlimited sparring sessions
              </div>
            </li>
            <li>
              <div className="icon-text-container">
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />{" "}
                Access to introduction video
              </div>
            </li>
            <li>
              <div className="icon-text-container">
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />{" "}
                Access to correction video
              </div>
            </li>
            <li>
              <div className="icon-text-container">
                <FontAwesomeIcon icon={faTimesCircle} className="cross-icon" />{" "}
                Personalized video each month
              </div>
            </li>
            <li>
              <div className="icon-text-container">
                <FontAwesomeIcon icon={faTimesCircle} className="cross-icon" />{" "}
                Personalized analysis of sparring games
              </div>
            </li>
            <li>
              <div className="icon-text-container">
                <FontAwesomeIcon icon={faTimesCircle} className="cross-icon" />{" "}
                24/7 IM/GM coach access
              </div>
            </li>
          </ul>
        </div>
        <div className="pricing-card premium">
          <h3>Premium Plan</h3>
          <p>Best for serious improvement</p>
          <ul>
            <li>
              <div className="icon-text-container">
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />{" "}
                Unlimited sparring sessions
              </div>
            </li>
            <li>
              <div className="icon-text-container">
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />{" "}
                Access to introduction video
              </div>
            </li>
            <li>
              <div className="icon-text-container">
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />{" "}
                Access to correction video
              </div>
            </li>
            <li>
              <div className="icon-text-container">
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />{" "}
                Personalized video each week
              </div>
            </li>
            <li>
              <div className="icon-text-container">
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />{" "}
                Analysis of all sparring games
              </div>
            </li>
            <li>
              <div className="icon-text-container">
                <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />{" "}
                24/7 IM/GM coach access
              </div>
            </li>
          </ul>
          <div className="pricing-footer">
            <h4>€50/month</h4>
            <button className="btn btn-primary">Choose Plan</button>
          </div>
        </div>
      </motion.div>

      <motion.div
        className="testimonials-section"
        initial={{ opacity: 0, y: 50 }}
        animate={{
          opacity: testimonialsSectionInView ? 1 : 0,
          y: testimonialsSectionInView ? 0 : 50,
        }}
        transition={{ duration: 1 }}
        ref={testimonialsSectionRef}
      >
        <h2>What Our Users Say</h2>
        <div className="testimonial">
          <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon left" />
          <p>
            PeerChess has completely transformed my chess game. The weekly
            sparring matches and personalized feedback are exactly what I needed
            to improve.
          </p>
          <FontAwesomeIcon icon={faQuoteRight} className="quote-icon right" />
          <h5>Gunnar Andersen, IM (soon)</h5>
        </div>
        <div className="testimonial">
          <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon left" />
          <p>
            The premium plan is worth every penny. The feedback from GM coaches
            is invaluable, and I've seen a noticeable improvement in my play.
          </p>
          <FontAwesomeIcon icon={faQuoteRight} className="quote-icon right" />
          <h5>Jane Smith, Advanced Player</h5>
        </div>
      </motion.div>

      <motion.div
        className="booking-call-section"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{
          opacity: bookingSectionInView ? 1 : 0,
          scale: bookingSectionInView ? 1 : 0.9,
        }}
        transition={{ duration: 1 }}
        ref={bookingSectionRef}
      >
        <div className="booking-text">
          <h2>
            Interested in PeerChess Premium Plan? Book a call with a coach!
          </h2>
          <p>
            Fill in your details and we’ll contact you to schedule a Zoom/Meet
            call.
          </p>
        </div>
        <div className="booking-form">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formCheckbox">
              <Form.Check
                type="checkbox"
                label="I would like to be contacted for a personalized Zoom/Meet session."
                checked={wantsMeeting}
                onChange={(e) => setWantsMeeting(e.target.checked)}
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </motion.div>

      <motion.div
        className="faq-section"
        initial={{ opacity: 0, y: 50 }}
        animate={{
          opacity: faqSectionInView ? 1 : 0,
          y: faqSectionInView ? 0 : 50,
        }}
        transition={{ duration: 1 }}
        ref={faqSectionRef}
      >
        <h2>Frequently Asked Questions</h2>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>What is PeerChess?</Accordion.Header>
            <Accordion.Body>
              PeerChess is an online platform offering personalized chess
              training with weekly sparring matches and tailored feedback.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>How does the Premium Plan work?</Accordion.Header>
            <Accordion.Body>
              The Premium Plan offers more in-depth training, including
              personalized videos and feedback from GM coaches.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              What is included in the Free Plan?
            </Accordion.Header>
            <Accordion.Body>
              The Free Plan includes unlimited sparring sessions and access to
              both introduction and correction videos.
            </Accordion.Body>
          </Accordion.Item>
          {/* Add more FAQ items as needed */}
        </Accordion>
      </motion.div>
    </div>
  );
};

export default Home;
