import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Modal,
  Button,
  Dropdown,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WeeklyVideo, WeekOption, SparringSummary } from "../types";
import {
  faHourglassHalf,
  faCheckCircle,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import axiosConfig from "../axiosConfig";
import "../css/WeeklySummaries.css";
import { useAuth } from "../AuthContext";
import LoadingSpinner from "../components/LoadingSpinner";

const WeeklySummaries: React.FC = () => {
  const [videos, setVideos] = useState<WeeklyVideo | null>(null);
  const [sparringSummaries, setSparringSummaries] = useState<SparringSummary[]>(
    []
  );
  const [selectedWeek, setSelectedWeek] = useState<string>("");
  const [availableWeeks, setAvailableWeeks] = useState<WeekOption[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedSummary, setSelectedSummary] =
    useState<SparringSummary | null>(null);
  const [videoLoading, setVideoLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(true);

  const { user } = useAuth();
  const currentUserId = user ? user.id : null;

  useEffect(() => {
    const fetchWeeks = async () => {
      try {
        const response = await axiosConfig.get("weekly-videos/weeks/");
        setAvailableWeeks(response.data);

        if (response.data.length > 0) {
          const today = new Date();
          const currentWeekOption = response.data.find(
            (weekOption: WeekOption) => {
              const weekDate = new Date(weekOption.week);
              return (
                today >= weekDate &&
                today < new Date(weekDate.getTime() + 7 * 24 * 60 * 60 * 1000)
              );
            }
          );
          if (currentWeekOption) {
            setSelectedWeek(currentWeekOption.week);
            fetchVideos(currentWeekOption.week);
            fetchSparringSummaries(currentWeekOption.week);
          }
        }
      } catch (error) {
        console.error("Error fetching weeks:", error);
      }
    };

    fetchWeeks();
  }, [currentUserId]);

  useEffect(() => {
    console.log("Is video loading?", videoLoading);
    console.log("Is image loading?", imageLoading);
  }, [videoLoading, imageLoading]);

  const fetchVideos = async (week: string) => {
    try {
      const response = await axiosConfig.get(`weekly-videos/?week=${week}`);
      if (response.data.length > 0) {
        setVideos(response.data[0]);
        setVideoLoading(false);
        setImageLoading(false);
      } else {
        setVideos(null);
        setVideoLoading(false);
        setImageLoading(false);
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
      setVideoLoading(false);
      setImageLoading(false);
    }
  };

  const fetchSparringSummaries = async (week: string) => {
    try {
      const response = await axiosConfig.get(`/weekly-sparrings/?week=${week}`);

      const filteredSummaries = response.data
        .filter(
          (summary: any) =>
            summary.review_status === "completed" ||
            summary.review_status === "in_progress"
        )
        .map((summary: any) => {
          const opponent =
            summary.requester.id === currentUserId
              ? summary.offer.user
              : summary.requester;

          // Extract the selected slot if available
          const selectedSlot = summary.selected_slot
            ? {
                start: new Date(summary.selected_slot.start),
                end: new Date(summary.selected_slot.end),
              }
            : null;

          return {
            opponent: `${opponent.first_name} ${opponent.last_name}`,
            requester_score: summary.requester_score,
            offer_score: summary.offer_score,
            date: selectedSlot
              ? selectedSlot.start.toISOString()
              : summary.date,
            game_link: summary.game_link,
            review_status: summary.review_status,
            coach_comments: summary.coach_comments,
            coach_badges: summary.coach_badges,
            analysis_study_link: summary.analysis_study_link,
          };
        });

      setSparringSummaries(filteredSummaries);
    } catch (error) {
      console.error("Error fetching sparring summaries:", error);
    }
  };

  const handleWeekChange = (eventKey: string | null) => {
    if (eventKey) {
      const selectedWeekOption = availableWeeks.find(
        (week) => week.week === eventKey
      );
      if (selectedWeekOption) {
        setSelectedWeek(selectedWeekOption.week);
        fetchVideos(selectedWeekOption.week);
        fetchSparringSummaries(selectedWeekOption.week);
      }
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const handleRowClick = (summary: SparringSummary) => {
    if (user?.subscription_status !== "premium") {
      console.log("Nice try! Now get your sub! :)");
      return;
    }
    setSelectedSummary(summary);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedSummary(null);
  };

  return (
    <Container className="weekly-summaries">
      <Row className="mb-4">
        <Col className="text-center">
          <Dropdown onSelect={handleWeekChange}>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              {selectedWeek ? formatDate(selectedWeek) : "Select Week"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {availableWeeks.map((weekOption, index) => (
                <Dropdown.Item key={index} eventKey={weekOption.week}>
                  {formatDate(weekOption.week)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>

      {videos && (
        <>
          <Row className="mb-5">
            <Col md={6} className="text-center">
              <h2>General Correction Video</h2>
              {videoLoading && (
                <div
                  style={{
                    position: "relative",
                    height: "300px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f8ede3", // Couleur de fond personnalisée
                  }}
                >
                  <LoadingSpinner
                    size="lg"
                    spinnerColor="#8d493a"
                    backgroundColor="#f8ede3"
                  />
                </div>
              )}

              <div
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                  overflow: "hidden",
                  maxWidth: "100%",
                  marginBottom: "10px",
                  display: videoLoading ? "none" : "block",
                }}
              >
                <iframe
                  src={videos.general_correction_video || ""}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  allowFullScreen
                  title="General Correction Video"
                  onLoad={() => setVideoLoading(false)} // Masquer le spinner lorsque la vidéo est chargée
                ></iframe>
              </div>
            </Col>

            <Col md={6} className="text-center">
              <h2>Position of the Week</h2>
              {imageLoading && (
                <div
                  style={{
                    position: "relative",
                    height: "300px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f8ede3", // Couleur de fond personnalisée
                  }}
                >
                  <LoadingSpinner
                    size="lg"
                    spinnerColor="#8d493a"
                    backgroundColor="#f8ede3"
                  />
                </div>
              )}

              <div
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                  overflow: "hidden",
                  maxWidth: "100%",
                  marginBottom: "10px",
                  display: imageLoading ? "none" : "block",
                }}
              >
                <img
                  src={
                    videos.position_of_the_week
                      ? `${process.env.REACT_APP_MEDIA_URL}/media/images/${videos.position_of_the_week}`
                      : ""
                  }
                  alt="Position of the Week"
                  className="img-fluid"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  onLoad={() => setImageLoading(false)} // Masquer le spinner lorsque l'image est chargée
                />
              </div>
            </Col>
          </Row>
        </>
      )}

      <Row className="mb-4">
        <Col>
          <h2 className="text-center">My Analysis</h2>
        </Col>
      </Row>

      <Row className="position-relative">
        <Col>
          <div
            className={
              user?.subscription_status !== "premium" ? "blurred-content" : ""
            }
          >
            <Table hover>
              <thead>
                <tr>
                  <th>Opponent</th>
                  <th>Score</th>
                  <th>Date</th>
                  <th style={{ width: "15%" }}>Game Link</th>
                  <th>Review Status</th>
                </tr>
              </thead>
              <tbody>
                {sparringSummaries.length > 0 ? (
                  sparringSummaries.map((summary, index) => {
                    const userScore =
                      summary.requester_score !== null &&
                      summary.offer_score !== null
                        ? summary.requester_score
                        : null;
                    const opponentScore =
                      summary.offer_score !== null &&
                      summary.requester_score !== null
                        ? summary.offer_score
                        : null;

                    return (
                      <tr
                        key={index}
                        className="clickable-row"
                        onClick={() => handleRowClick(summary)}
                      >
                        <td>{summary.opponent}</td>
                        <td>
                          {userScore !== null && opponentScore !== null
                            ? `${userScore} - ${opponentScore}`
                            : "Score Not Available"}
                        </td>
                        <td>{formatDate(summary.date)}</td>
                        <td>{summary.game_link}</td>
                        <td>
                          {summary.review_status === "in_progress" ? (
                            <FontAwesomeIcon
                              icon={faHourglassHalf}
                              style={{ color: "#8d493a" }}
                              className="rotate-animation"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              style={{ color: "#8d493a" }}
                              className="bounce-animation"
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center">
                      You need to play sparring matches first!
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          {user?.subscription_status !== "premium" && (
            <div className="upgrade-overlay text-center">
              <FontAwesomeIcon icon={faLock} className="lock-icon" />
              <Button href="/premium/" className="btn-premium">
                Become Premium and get your games analyzed !
              </Button>
            </div>
          )}
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Sparring Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedSummary && (
            <div>
              {selectedSummary.review_status === "in_progress" ? (
                <div className="text-center">
                  <FontAwesomeIcon
                    icon={faHourglassHalf}
                    size="4x"
                    style={{ color: "#8d493a", marginBottom: "20px" }}
                    className="rotate-animation"
                  />
                  <p>
                    The coach is analyzing your games! You will receive an email
                    when he's finished!
                  </p>
                </div>
              ) : (
                <div>
                  <div className="text-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size="4x"
                      style={{ color: "#8d493a", marginBottom: "20px" }}
                      className="bounce-animation"
                    />
                  </div>
                  <div className="badges-container text-left">
                    {selectedSummary.coach_badges?.map((badge) => (
                      <span
                        key={badge.id}
                        className="badge"
                        style={{
                          backgroundColor: badge.color,
                          color: "#fff",
                          marginRight: "5px",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          display: "inline-block",
                        }}
                      >
                        {badge.name}
                      </span>
                    ))}
                  </div>
                  <div className="text-left mt-3">
                    <p>
                      <strong>Coach Comments:</strong>{" "}
                      {selectedSummary.coach_comments}
                    </p>
                    <p>
                      <strong>Lichess Study:</strong>{" "}
                      <a
                        href={selectedSummary.analysis_study_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {selectedSummary.analysis_study_link}
                      </a>
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default WeeklySummaries;
