import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Accordion,
  Form,
  Alert,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faCheck } from "@fortawesome/free-solid-svg-icons"; // Ajout de faCheckCircle
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axiosConfig from "../axiosConfig";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../AuthContext";
import toast, { Toaster } from "react-hot-toast";
import "../css/PaymentPage.css";

const PaymentPage: React.FC = () => {
  const [cbLogo, setCbLogo] = useState<string>("");
  const [paypalLogo, setPaypalLogo] = useState<string>("");
  const [mastercardLogo, setMastercardLogo] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation(); // Use location hook
  const stripe = useStripe();
  const elements = useElements();
  const { refetchProfile } = useAuth();

  const fetchImagePaths = async () => {
    try {
      const cbResponse = await axiosConfig.get(
        `/get-image-path/payment/cb.png/`
      );
      setCbLogo(cbResponse.data.image_url);

      const paypalResponse = await axiosConfig.get(
        `/get-image-path/payment/paypal.png/`
      );
      setPaypalLogo(paypalResponse.data.image_url);

      const mastercardResponse = await axiosConfig.get(
        `/get-image-path/payment/mastercard.png/`
      );
      setMastercardLogo(mastercardResponse.data.image_url);
    } catch (error) {
      console.error("Error fetching image paths:", error);
    }
  };

  useEffect(() => {
    fetchImagePaths();

    const query = new URLSearchParams(location.search);
    const paymentId = query.get("paymentId");
    const PayerID = query.get("PayerID");

    if (paymentId && PayerID) {
      confirmPayPalPayment(paymentId, PayerID);
    } else if (query.get("status") === "error") {
      handlePayPalCancel();
    }
  }, [location.search]);

  const confirmPayPalPayment = async (paymentId: string, PayerID: string) => {
    try {
      const response = await axiosConfig.get("/api/payment/success/", {
        params: {
          paymentId,
          PayerID,
        },
      });

      if (response.status === 200) {
        await refetchProfile();

        // Show success toast
        toast.success("Congratulations, you are now a premium member!");

        // Redirect to the weekly summaries page
        navigate("/weekly-summaries");
      } else {
        throw new Error("Payment verification failed.");
      }
    } catch (error) {
      console.error("Error confirming PayPal payment:", error);
      toast.error(
        "There was an issue with your payment. Please try again or contact support."
      );
      navigate("/premium");
    }
  };

  const handlePayPalCancel = () => {
    setError("Payment declined, please try again.");
    toast.error("Payment declined, please try again.");
    navigate("/premium");
  };

  const handlePayment = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe.js has not yet loaded.");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      console.error("CardElement not found.");
      return;
    }

    try {
      const { data } = await axiosConfig.post("/create-payment-intent/", {
        amount: 2999, // Amount in cents
      });

      const { clientSecret } = data;

      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: "John Doe",
          },
        },
      });

      if (paymentResult.error) {
        console.error("Payment failed:", paymentResult.error.message);
        setError("Payment declined, please try again.");
      } else {
        if (paymentResult.paymentIntent?.status === "succeeded") {
          await axiosConfig.post("/upgrade-to-premium/");

          await refetchProfile();

          // Show success toast
          toast.success("Congratulations, you are now a premium member!");

          // Redirect to the weekly summaries page
          navigate("/weekly-summaries");
        }
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      setError("Error processing payment. Please try again.");
    }
  };

  const handlePayPalPayment = async () => {
    try {
      const { data } = await axiosConfig.post("/create-paypal-payment/", {
        success_url: `${window.location.origin}/premium?status=success`,
        cancel_url: `${window.location.origin}/premium?status=error`,
      });

      if (data.approval_url) {
        window.location.href = data.approval_url;
      } else {
        setError("Payment declined, please try again.");
      }
    } catch (error) {
      console.error("Error initiating PayPal payment:", error);
      setError("Payment declined, please try again.");
    }
  };

  return (
    <Container className="payment-page">
      <Toaster position="top-right" />
      <Row>
        <Col md={6}>
          <div className="text-container">
            <h2 className="benefits-title">Benefits with Premium</h2>{" "}
            {/* Titre ajouté */}
            <p>
              <FontAwesomeIcon icon={faCheck} className="check-icon" />{" "}
              Customized Coaching
            </p>
            <p>
              <FontAwesomeIcon icon={faCheck} className="check-icon" />{" "}
              Exclusive Content
            </p>
            <p>
              <FontAwesomeIcon icon={faCheck} className="check-icon" />{" "}
              Community Support
            </p>
            <p>
              <FontAwesomeIcon icon={faCheck} className="check-icon" /> Progress
              Tracking
            </p>
          </div>
        </Col>

        <Col md={6}>
          <div className="payment-container">
            {error && <Alert variant="danger">{error}</Alert>}
            <Card className="payment-summary mb-4">
              <Card.Body>
                <h2>Become premium with PeerChess!</h2>
                <div className="summary-item">
                  <span className="summary-label">Total amount</span>
                  <span className="summary-value">€29.99</span>
                </div>
                <div className="summary-item">
                  <span className="summary-label">Next payment:</span>
                  <span className="summary-value">18/09/2024</span>
                </div>
              </Card.Body>
            </Card>
            <div className="custom-accordion">
              <Accordion
                defaultActiveKey="0"
                className="payment-method-accordion"
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Credit and Debit Card
                    <div className="logos">
                      {cbLogo && (
                        <img
                          src={`${process.env.REACT_APP_MEDIA_URL}/${cbLogo}`}
                          alt="cb-logo"
                          className="logo-img"
                        />
                      )}
                      {mastercardLogo && (
                        <img
                          src={`${process.env.REACT_APP_MEDIA_URL}/${mastercardLogo}`}
                          alt="mastercard-logo"
                          className="logo-img"
                        />
                      )}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Form onSubmit={handlePayment}>
                      <Form.Group controlId="formCardNumber">
                        <Form.Label>Card Number</Form.Label>
                        <CardElement
                          className="form-control"
                          options={{ hidePostalCode: true }}
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn-block mt-3"
                      >
                        Start Subscription
                      </Button>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    PayPal
                    {paypalLogo && (
                      <img
                        src={`${
                          process.env.REACT_APP_MEDIA_URL ||
                          "http://localhost:8000"
                        }/${paypalLogo}`}
                        alt="paypal-logo"
                        className="paypal-logo"
                      />
                    )}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Button
                      variant="primary"
                      className="btn-paypal btn-block mt-3"
                      onClick={handlePayPalPayment}
                    >
                      Continue to PayPal
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="text-center mt-4">
            <FontAwesomeIcon icon={faLock} size="2x" className="text-muted" />
            <p className="mt-2 text-muted">
              Your payment information is secure and encrypted.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentPage;
