import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axiosConfig from "../axiosConfig";
import { useAuth } from "../AuthContext";
import Cookies from "js-cookie";
import "../css/Login.css";

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const [logo, setLogo] = useState<string | null>(null);
  const { isAuthenticated, login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }

    document.body.classList.add("login-page");

    return () => {
      document.body.classList.remove("login-page");
    };
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    setBackgroundImage("media/images/PeerChess.png");
    setLogo("media/images/logo/PeerChess.png");
  }, []);

  const handleGoogleLogin = async () => {
    try {
      const redirectUri = process.env.REACT_APP_API_URL + "/auth/callback/";
      const googleLoginUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=691607737462-s19tectj18tk24kdq97eqlgrepqkervc.apps.googleusercontent.com&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&response_type=code&scope=email%20profile`;

      window.location.href = googleLoginUrl;
    } catch (error) {
      console.error("Failed to initiate Google login", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const csrfToken = Cookies.get("csrftoken");
      console.log("CSRF Token:", csrfToken);

      const response = await axiosConfig.post(
        "/login/",
        {
          username,
          password,
        },
        {
          headers: {
            "X-CSRFToken": csrfToken,
          },
        }
      );

      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        login(response.data.token);
        navigate("/");
      } else {
        setError("No token received. Please check the server response.");
      }
    } catch (error) {
      console.error("Login failed:", error);
      setError("Login failed. Please check your credentials.");
    }
  };

  return (
    <div id="login-container" className="container">
      <div className="login-left">
        {logo && (
          <div className="logo-container">
            <Link to="/">
              <img
                src={`${process.env.REACT_APP_MEDIA_URL}/${logo}`}
                alt="Logo"
                className="logo-img-login"
              />
            </Link>
          </div>
        )}
        <h1>Welcome back</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="remember-me-container">
            <div className="remember-me">
              <input type="checkbox" id="rememberMe" />
              <label htmlFor="rememberMe">Remember me</label>
            </div>
            <a href="/reset-password" className="forgot-password">
              Forgot password?
            </a>
          </div>
          <button type="submit" className="btn-login">
            LOGIN
          </button>
        </form>
        {error && <p className="text-danger mt-3">{error}</p>}
        <div className="social-login">
          <p>or</p>
          <button className="btn-google" onClick={handleGoogleLogin}>
            <img
              src="https://img.icons8.com/color/16/000000/google-logo.png"
              alt="Google"
            />
            Sign in with Google
          </button>
          <p>
            Don't have an account?{" "}
            <a href="/register" className="signup-link">
              Sign up for free
            </a>
          </p>
        </div>
      </div>
      <div
        className="login-right"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_MEDIA_URL}/${backgroundImage})`,
        }}
      ></div>
      <div className="chess-sidebar">
        <div className="chess-images-container">
          <div className="chess-images">
            {Array.from({ length: 20 }).map((_, index) => (
              <img
                key={index}
                src={`${process.env.REACT_APP_MEDIA_URL}/media/images/chess_position.png`}
                alt="Chess Position"
                className="chess-image"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
