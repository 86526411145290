import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

const GoogleCallback = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const apiUrl =
        process.env.REACT_APP_MEDIA_URL || "http://localhost:8000/api/";
      const apiOrigin = new URL(apiUrl).origin;

      if (event.origin !== apiOrigin) return;

      console.log("Message received from popup:", event.data);

      const { token } = event.data;

      if (token) {
        console.log("Token received:", token);
        login(token);
        window.close();
        navigate("/dashboard");
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [login, navigate]);

  return null;
};

export default GoogleCallback;
