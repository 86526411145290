// AuthContext.tsx
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "./axiosConfig";
import { AuthContextType } from "./types";

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true); // Ajoutez un état de chargement

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Token ${token}`;
      refetchProfile();
    } else {
      setIsAuthenticated(false);
      setUser(null);
      setLoading(false); // Fin du chargement
    }
  }, []);

  const login = (token: string) => {
    localStorage.setItem("token", token);
    axios.defaults.headers.common["Authorization"] = `Token ${token}`;
    setIsAuthenticated(true);
    refetchProfile();
  };

  const refetchProfile = async () => {
    try {
      const response = await axios.get("/profile/");
      setUser(response.data);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Error fetching profile:", error);
      localStorage.removeItem("token");
      delete axios.defaults.headers.common["Authorization"];
      setIsAuthenticated(false);
      setUser(null);
    } finally {
      setLoading(false); // Fin du chargement
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common["Authorization"];
    setIsAuthenticated(false);
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
        refetchProfile,
        setUser,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
