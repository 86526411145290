import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../AuthContext";
import axiosConfig from "../axiosConfig";
import toast from "react-hot-toast";

const PaymentResultPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { refetchProfile } = useAuth();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get("status");

    const handlePaymentResult = async () => {
      if (status === "success") {
        try {
          await axiosConfig.post("/upgrade-to-premium/");
          await refetchProfile();
          toast.success("Congratulations, you are now a premium member!", {
            id: "success-toast", // Ajouter un ID pour éviter les doublons
          });
          navigate("/weekly-summaries");
        } catch (error) {
          console.error("Error upgrading to premium:", error);
          toast.error(
            "There was an issue upgrading your account. Please contact support.",
            {
              id: "error-toast", // Ajouter un ID pour éviter les doublons
            }
          );
          navigate("/premium");
        }
      } else {
        toast.error("Payment declined, please try again.", {
          id: "error-toast", // Ajouter un ID pour éviter les doublons
        });
        navigate("/premium");
      }
    };

    handlePaymentResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Ajouter des dépendances ici peut provoquer plusieurs exécutions

  return <div>Processing your payment...</div>;
};

export default PaymentResultPage;
